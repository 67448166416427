/* You can add global styles to this file, and also import other style files */
.panel-fixed-height {
    height: 470px;
}
.custom-margin {
    float: right;
    margin-top: 1rem;
}
.ngx-pagination {
    padding: 0px !important;
}
#changeIcon {
    color: white !important;
}

.btn-style {
    width: 20px !important;
    height: 20px !important;
}


app-posscreen-simple {
    height: 100vh;
    display: block;
}

.map-container {
    width: 100% !important;
  }