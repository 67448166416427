@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

.modal-header {
    background-color: #E6E6E6;
    border-bottom: 1px solid #dee2e6 !important;
}

.modal-full {
    min-width: 90% !important;
    min-height: 90% !important;
    padding: 0;

    .modal-header {
        background-color: #fff;
        border-bottom: 0px;
    }

    .modal-content {
        height: 97.3%;
        position: absolute;
    }

    .modal-body {
        padding: 0;
        min-height: 90% !important;

        .container-fluid {
            margin: 0;
            padding: 0;
        }
    }

    .detailed-area {
        background-color: #F9F9FA;

        .modal-header {
            display: none;
        }

        .modal-body {
            .panel {
                margin: 20px;
            }

            .footer {
                margin: 0 5px 30px 0;
            }
        }
    }

    .module-loader {
        background-color: #fff;
    }
}


.help-block {
    color: #dc3545
}


.debug {
    border: 1px solid red;
}

.debug-1 {
    border: 1px solid blue;
}


.record-browser {
    background-color: #fff;

    .sidebar-scroller {
        width: 17rem;
        background-color: #efefef;

        .header {
            font-size: 15px;
        }

        .notification li {
            background-color: #fff;
        }
    }

    .viewer {
        background-color: #fff;
        ;
    }
}

.font-xxxlarge {
    font-size: 24px;
}

.form-group {
    margin-bottom: 0.75rem;
}

.min-height-table {
    min-height: 250px;
}

.table-in-panel {
    overflow-y: scroll;
    height: 370px;
}

/* iphone X , XS, 11 Pro, 12 Mini */
//   @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) { }


@media only screen and (max-width: 475px) {
    .form-label {
        margin-top: 10px;
    }

    .page-breadcrumb {
        margin: 0 0 .5rem;
    }

    .page-wrapper .page-content .subheader {
        margin-bottom: .5rem;
    }

    .page-wrapper .page-content {
        margin: .5rem;
    }
}


@media only screen and (min-device-width: 375px) and (max-device-height: 812px) {
    .hide {
        display: block;
    }

    tbody {
        overflow-x: scroll;
    }

    .btn1 {
        height: 40px;
        width: 70px;
    }
}


.datatable td {
    padding: 7px;
    vertical-align: middle;
}

@keyframes btnSpinner {
    to {
        transform: rotate(360deg);
    }
}

.btn-spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: btnSpinner .8s linear infinite;
}

@media (min-width: 576px) {
    .card-deck .card {
        flex: 1 0 30%;
        margin-bottom: 10px;
    }
}

@media (min-width: 576px) {
    .account-quick-view {
        .card-deck .card {
            flex: 1 0 25%;
            margin-bottom: 10px;

            .card-body {
                padding: 5px 10px;
            }
        }
    }
}

.badge {
    .close {
        margin-left: 10px;
        font-size: inherit;
        color: inherit;
        text-shadow: none;
    }
}


.summary-stats {
    .card {
        .icon-wrapper {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .fal {
            width: 50px;
            height: 50px;
            font-size: 28px;
            padding: 10px 15px;
        }
        .fal-for-wrapper {
            padding: 10px 10px !important;
        }
        h4 {
            font-size: 16px;
            font-weight: 500;
            .detail {
                font-size: 18px;
                font-weight: 100;
            }
        }
    }
}
